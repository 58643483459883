'use client'

import React from 'react'
import {Analytics} from '~/utils/Analytics'
import {type ClickEvent} from '~/utils/Analytics/types'

export type MWDSUnstyledButtonProps =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    analyticsEvent: ClickEvent | undefined
    preventDefault?: boolean
  }

/**
 * The most bare-bones <button> that behaves like we want all buttons to.
 * Maybe useful for very custom, not-in-the-design-system buttons that don't want
 * behaviors like the loading state from the DSButton, which can be hard to work around / replace.
 * Hopefully only rarely needed outside DSButton in practice?
 */
const MWDSUnstyledButtonComponent = (
  props: MWDSUnstyledButtonProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const {
    onClick,
    analyticsEvent,
    type,
    // If the button type="submit" then we should not prevent the default behavior of onClick and onKeyDown,
    // specifically triggering onSubmit on the parent <form> element. Useful for react-hook-form
    preventDefault = type !== 'submit',
    ...htmlButtonProps
  } = props

  const onFocusCaptureProp = htmlButtonProps.onFocusCapture
  htmlButtonProps.onFocusCapture = (event, ...args) => {
    onFocusCaptureProp?.(event, ...args)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const buttonEl = event.target as HTMLButtonElement
    analyticsEvent &&
      Analytics.trackClick(
        Analytics.mergeProperties(analyticsEvent, {text: buttonEl.innerText})
      )
    onClick?.(event)
  }

  return (
    <button
      ref={ref}
      type={type ?? 'button'}
      {...htmlButtonProps}
      onClick={e => {
        if (preventDefault) {
          e.preventDefault()
        }
        handleClick(e)
      }}
    >
      {htmlButtonProps.children}
    </button>
  )
}
export const MWDSUnstyledButton = React.forwardRef<
  HTMLButtonElement,
  MWDSUnstyledButtonProps
>(MWDSUnstyledButtonComponent)
